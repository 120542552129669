import React, { FC, useContext } from "react";
import { Helmet } from "react-helmet";
import { CityContext } from "../context/CityContext";

interface IHelmentComponent {
  title?: string;
  description?: string;
  canonicalHref?: string;
}
const HelmetComponent: FC<IHelmentComponent> = ({
  title,
  description,
  canonicalHref,
}) => {
  const { cityNameForSeo, workingHours } = useContext(CityContext);

  const defaultTitle = `МИКАfood - Доставка еды в ${cityNameForSeo}`;
  const defaultDescription = `30 видов пицц, ароматная шаурма, печеная картошка с различными салатиками, картофель фри, салаты на любой вкус, настоящие шашлыки на углях, блюда японской и китайской кухни. Доставка ${workingHours}`;

  let links = [
    {
      rel: "apple-touch-icon",
      sizes: "57x57",
      href: "/static/favicon/apple-icon-57x57.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "60x60",
      href: "/static/favicon/apple-icon-60x60.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "72x72",
      href: "/static/favicon/apple-icon-72x72.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "76x76",
      href: "/static/favicon/apple-icon-76x76.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "114x114",
      href: "/static/favicon/apple-icon-114x114.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "120x120",
      href: "/static/favicon/apple-icon-120x120.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "144x144",
      href: "/static/favicon/apple-icon-144x144.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "152x152",
      href: "/static/favicon/apple-icon-152x152.png",
    },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: "/static/favicon/apple-icon-180x180.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "192x192",
      href: "/static/favicon/android-icon-192x192.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "32x32",
      href: "/static/favicon/favicon-32x32.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "96x96",
      href: "/static/favicon/favicon-96x96.png",
    },
    {
      rel: "icon",
      type: "image/png",
      sizes: "16x16",
      href: "/static/favicon/favicon-16x16.png",
    },
    { rel: "manifest", href: "/static/favicon/manifest.json" },
  ];

  if (canonicalHref) {
    links.push({
      rel: "canonical",
      href: canonicalHref,
    });
  }

  return (
    <Helmet
      htmlAttributes={{ lang: "en" }}
      title={title || defaultTitle}
      meta={[
        {
          name: "viewport",
          content: "width=device-width, initial-scale=1",
        },
        { name: "description", content: description || defaultDescription },
        { name: "msapplication-TileColor", content: "#ffffff" },
        {
          name: "msapplication-TileImage",
          content: "/static/favicon/ms-icon-144x144.png",
        },
        { name: "theme-color", content: "#ffffff" },
        { property: "og:title", content: title || defaultTitle },
        {
          property: "og:description",
          content: description || defaultDescription,
        },
        { property: "og:image", content: "/static/favicon/social.png" },
        {
          name: "yandex-verification",
          content: "31589ca1b650fe72",
        },
      ]}
      link={links}
    />
  );
};

export default HelmetComponent;
